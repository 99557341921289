// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../public/img/genre/banner_lecture.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "h4[data-v-1d52e9ab] {\n  margin: 0 !important;\n  font-weight: 500 !important;\n  font-size: 2.4rem !important;\n  line-height: 6.6rem !important;\n}\ninput[data-v-1d52e9ab], textarea[data-v-1d52e9ab] {\n  padding: 0;\n  width: 100%;\n  border: 0;\n  font-size: 2rem;\n  line-height: 6rem;\n}\ntextarea[data-v-1d52e9ab] {\n  line-height: 3rem;\n  min-height: 14rem;\n  padding: 3rem 0;\n}\np[data-v-1d52e9ab] {\n  color: #979797;\n  font-size: 2rem;\n}\n.lecture-top[data-v-1d52e9ab] {\n  margin: 1rem 0 2.2rem;\n}\n.lecture-banner[data-v-1d52e9ab] {\n  height: 18.8rem;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center/100%;\n}\n.lecture-profile[data-v-1d52e9ab] {\n  width: 10rem;\n  height: 10rem;\n  margin: -5rem auto 0;\n  border-radius: 50%;\n  -webkit-box-shadow: 0.2rem 0.2rem 0.4rem 0 #00000014;\n          box-shadow: 0.2rem 0.2rem 0.4rem 0 #00000014;\n}", ""]);
// Exports
module.exports = exports;
