<template>
    <div class="container">

      <div class="lecture-top">
        <div class="lecture-banner"></div>
        <div class="lecture-profile" :style="{background:` url(${artistData.imgUrl1}) no-repeat center / cover #fff `}" ></div>
      </div>

      <page-header
        :title="`문의할 내용을 알려주세요.`"
        titleSize="3.2rem"
        :subtitle="'전문 상담사가 확인 후 최대한 빨리 연락드립니다.'"
      />

      <div>
        <div class="ui-border-line ui-h-0 ui-mb-3"></div>
        <h4>주최사명</h4>
        <div class="ui-border-line"></div>
        <input type="text" v-model="formData.hostName" placeholder="주최사명을 입력해주세요." required>
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <h4>행사명</h4>
        <div class="ui-border-line"></div>
        <input type="text" v-model="formData.eventName" placeholder="행사명을 입력해주세요." required>
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <h4>강연 일정</h4>
        <div class="ui-border-line"></div>
        <input type="text" v-model="formData.runday" v-mask="'####.##.## / ##시 ##분'" placeholder="강연 일정을 입력해주세요." required>
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mb-1"></div>
        <h4>강연 장소 (시/구/동)</h4>
        <div class="ui-border-line"></div>
        <input
          type="text"
          v-model="formData.location"
          required
          placeholder="강연 장소를 입력해주세요."
        />
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mb-3"></div>
        <h4>강연 대상</h4>
        <div class="ui-border-line"></div>
        <input type="text" v-model="formData.lectureTarget" placeholder="강연 대상을 입력해주세요." required>
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mb-3"></div>
        <h4>강연 주제</h4>
        <div class="ui-border-line"></div>
        <input type="text" v-model="formData.lectureTopic" placeholder="강연 주제를 입력해주세요." required>
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mb-3"></div>
        <h4>강연료(부가세 포함)</h4>
        <div class="ui-border-line"></div>
        <input type="text" v-model="formData.lecturePrice" placeholder="강연료를 입력해주세요." required>
        <div class="ui-border-line" style="height: 10px"></div>
      </div>
  
      <footer-box
        submitText="예약 문의하기"
        :submitCb="() => postOffer()"
        :submitDisabled="!validate"
      >
      </footer-box>
    </div>
  </template>
  
  <script>
  import PageHeader from '@/components/common/PageHeader'
  import FooterBox from '@/components/common/FooterBox'
  
  export default {
    components: {
      PageHeader,
      FooterBox,
    },
    data() {
      return {
        formData: {
          genre: '',
          location: null,
          runday: null,
          hostName: null,
          eventName: null,
          lectureTarget: null,
          lectureTopic: null,
          lecturePrice: null,
          itemType: '',
        },
        // confirm: false,
        artistData: {},
      }
    },
    computed: {
      validate() {
        let validate = true
        for (let key in this.formData) {
          if (!this.formData[key]) {
            validate = false
            break
          }
        }
        return validate
      },
    },
    beforeMount() {
      const { artistidx } = this.$route.params
  
      let artistData = sessionStorage.getItem(`artistData:${artistidx}`)
  
      if (!artistidx || !artistData) {
        this.$router.back()
        return
      }
  
      this.artistData = JSON.parse(artistData)
      this.formData.genre = this.artistData.genre
      this.formData.itemType = '프로필';
    },
    methods: {
      postOffer() {
        if (!this.validate) return
        // if (!this.confirm) {
        //   this.$store.commit('setModal', {
        //     show: true,
        //     title: '다시 한번 확인하세요!',
        //     body: `${this.formData.price}원으로\n견적을 보내시겠어요?`,
        //     confirm: '보내기',
        //     confirmCb: modal => {
        //       modal.hide()
        //       this.confirm = true
        //       this.postOffer()
        //     },
        //   })
        //   return
        // } else {
        //   this.confirm = false
        // }
  
        const req = {
          method: 'post',
          url: `/client/${this.$route.params.artistidx}/cusOrder`,
          data: this.formData,
        }
  
        this.$http(req)
          .then(({ data }) => {
            log(...Object.values(req), data)
  
            const resultData = this.$lib.resultCheck(data.resultData)
  
            if (resultData.success) {
              this.$toast('문의가 전달되었습니다').push('/home')
            } else {
              this.$alert(resultData)
            }
          })
          .catch(log.error)
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  h4 {
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 2.4rem !important;
    line-height: 6.6rem !important;
  }
  
  input, textarea {
    padding: 0;
    width: 100%;
    border: 0;
    font-size: 2rem;
    line-height: 6rem;
  }
  
  textarea {
    line-height: 3rem;
    min-height: 14rem;
    padding: 3rem 0;
  }
  
  p {
    color: #979797;
    font-size: 2rem;
  }

  .lecture-top {
    margin: 1rem 0 2.2rem;
  }

  .lecture-banner {
    height: 18.8rem; 
    background: url(../../../../public/img/genre/banner_lecture.svg) no-repeat center / 100%;
  }

  .lecture-profile {
    width: 10rem; 
    height: 10rem; 
    margin: -5rem auto 0;
    border-radius: 50%;
    box-shadow: 0.2rem 0.2rem 0.4rem 0 #00000014;
    // background: url(../../../../public/img/genre/profile_lecture.svg) no-repeat center / 100%;
  }

  </style>